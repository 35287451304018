const normalizeQuery = (str) => {
  return str?.replaceAll(' ', '-').toLowerCase()
}

const sortedImage = (string) => {
  let arr = string.split(' ').join('_').split('-')
  // let arr = string.split('-')
  // return parseInt(arr[arr.length - 1])
  // return arr[arr.length - 1]
  if (isNaN(parseInt(arr[arr.length - 1])) == true) {
    return 999
  } else return parseInt(arr[arr.length - 1])
}

export {
  normalizeQuery,
  sortedImage
}