import React from 'react';
import CustomFont from '../CustomFont';
import './searchInput.scss';

const SearchInput = ({
  handleValue,
  handleSearch,
  handleClickSearch,
  handleReset,
  inputValue }) => {

  return (
    <div className="container__search--input t--MINI--content">
      <input
        type="text"
        // placeholder='Search…'
        placeholder='Name, Location, Fruits, Colors, Objects, etc.'
        value={inputValue}
        className='medium__font'
        onKeyDown={() => handleSearch(event)}
        onChange={() => handleValue(event)}
        autoFocus={true}
        autoComplete="off"
      />
      {inputValue.length >= 3 &&
      <div className="container__CTA--input">
          <CustomFont
            className={"small__font t--UPPS"}
            content={"search"}
            tag={"button"}
            onClick={handleClickSearch}
          />
          <CustomFont
            className={"small__font t--UPPS"}
            content={"reset"}
            tag={"button"}
            onClick={handleReset}
          />
      </div>
      }
    </div>
  );
};

export default SearchInput;
