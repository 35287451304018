import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DataContext from '../../config/dataContext';
import { motion } from "framer-motion/dist/framer-motion";
import CustomFont from '../CustomFont';
import SymbolText from '../SymbolText';
import './header.scss';

const Header = () => {
  const globalData = useContext(DataContext)
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  const handleBack = () => {
    goBack()
    globalData.handleCloseMenu()
  }
  
  const handlePaddingValue = () => {
    var finalPadding = 227
    return finalPadding
  }

  let p = 15
  let h = 12
  let P = p * 2
  let H = h / 2
  let G = globalData.maxHeight
  let PT = G - P - H
  let Z = PT / 2

  return (
    <header className="wrapper__header">
      <div className={`container__header ${globalData.isLogoCentered ? "w--100" : "w--20"}`}>
        <motion.div
          className="container__back--btn"
          animate={{
            y: globalData.isAllDataLoaded ? 0 : "-4rem"
          }}
          transition={{
            ease: "easeInOut",
            default: { duration: 0.4 },
            delay: 0.4
          }}
          // animate={{
          //   y: globalData.isLogoCentered ? 0 : "-4rem"
          // }}
          // transition={{
          //   ease: "easeInOut",
          //   default: { duration: 0.4 },
          //   delay: globalData.isLogoCentered ? 0.2 : 0.1
          // }}
        >
          <SymbolText
            className='small__font t--UPPS'
            content='back'
            symbol='←'
            symbolPosition='left'
            onClick={handleBack}
          />
        </motion.div>
        <motion.div
          className={globalData.isLogoCentered === "big" ? "container__logo is--BIG" : "container__logo"}
          initial={{
            paddingTop: `${Z}px`
          }}
          animate={{
            paddingTop: globalData.isAllDataLoaded === true ? 0 : `${Z}px`
          }}
          transition={{
            ease: "easeInOut",
            default: { duration: 0.6 },
          }}
          // initial={{
          //   marginTop: 0,
          //   // fontSize: '1.71429rem',
          //   // lineHeight: '2.2rem',
          //   // letterSpacing: 0
          // }}
          // animate={{
          //   marginTop: globalData.isLogoCentered === 'big' ? '4.2rem' : 0,
          //   // fontSize: '5.71429rem',
          //   // lineHeight: '6',
          //   // letterSpacing: '-0.2rem'
          // }}
          // transition={{
          //   ease: "easeInOut",
          //   default: { duration: 0.8 },
          //   // delay: globalData.isLogoCentered === true && globalData.isMenuActive === true ? 0.4 : 0
          // }}
        >
          <Link
            to="/"
            className="logo--link"
            onClick={globalData.handleCloseMenu}
          >
            <motion.div
              id="LOGO"
              className="a--LINK"
              initial={{
                paddingLeft: 0,
              }}
              // ADD CONDITION WITH WIDTH FOR RESPONSIVE
              animate={{
                marginLeft: globalData.isLogoCentered === true && globalData.isMenuActive === true && globalData.maxWidth >= globalData.mobileWidth ? handlePaddingValue() : 0,
              }}
              transition={{
                ease: "easeInOut",
                default: { duration: 0.4 },
                delay: globalData.isLogoCentered === true && globalData.isMenuActive === true ? 0.4 : 0
              }}
            >
              <CustomFont
                tag='p'
                className={`${globalData.isLogoCentered === true ? "small__font" : "small__font"} t--UPPS`}
                content={`${globalData.artistName} studio`}
              />
            </motion.div>
          </Link>
        </motion.div>
        <motion.div
          className="container__menu--btn"
          onClick={globalData.handleMenu}
          // initial={{
          //   y: "-4rem"
          // }}
          animate={{
            y: globalData.isAllDataLoaded ? 0 : "-4rem"
          }}
          transition={{
            ease: "easeInOut",
            default: { duration: 0.4 },
            delay: 0.4
          }}
        >
          <motion.div
            className="MENU a--LINK"
            initial={{
              y: 0,
            }}
            animate={{
              y: globalData.isMenuActive ? "-4rem" : 0
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.4 },
              delay: globalData.isMenuActive ? 0.2 : 0
            }}
          >
            <CustomFont
              tag='p'
              className='small__font t--UPPS'
              content='menu'
            />
          </motion.div>
          <motion.div
            className="CLOSE a--LINK"
            initial={{
              x: "8rem",
            }}
            animate={{
              x: globalData.isMenuActive ? 0 : "8rem"
            }}
            transition={{
              ease: "easeInOut",
              default: { duration: 0.4 },
              delay: globalData.isMenuActive ? 0.2 : 0
            }}
          >
            <CustomFont
              tag='p'
              className='small__font t--UPPS'
              content='close'
            />
          </motion.div>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;