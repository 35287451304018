import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../config/dataContext";
import { motion } from "framer-motion/dist/framer-motion";
import CustomFont from "../CustomFont";
import "./menu.scss";

const Menu = () => {
  const globalData = useContext(DataContext);
  const mainLinks = [
    {name: "projects", slug: "index&type=1&sortBy=type"},
    {name: "more exhibitions", slug: "more-exhibitions"},
    {name: "biography", slug: "biography"},
    {name: "press", slug: "press"},
    {name: "monographs", slug: "monographs"},
    {name: "videos", slug: "videos"},
    {name: "search", slug: "search"},
  ]
  const secondaryLinks = [
    {name: "about", slug: "about"},
    {name: "contact", slug: "contact"},
    {name: "imprint", slug: "imprint"},
  ]

  console.log(globalData.informations.shop);

  return (
    <motion.div
      className="wrapper__menu"
      style={{
        height: globalData.maxHeight,
        backgroundColor: `#${globalData.currentMenuColor.hexa}`,
      }}
      initial={{
        x: "100%",
      }}
      animate={{
        x: globalData.isMenuActive ? 0 : "100%",
      }}
      transition={{
        ease: "easeInOut",
        default: { duration: 0.8 },
        // delay: 0.15
      }}
    >
      <div className="container__menu">
        <div className="container__top">
          <div className="container__left">
            {mainLinks.map((link) => 
              <li
                key={link.name}
                className="link--item"
              >
                <Link
                  to={`/${link.slug}`}
                  className="a--LINK"
                  onClick={globalData.handleCloseMenu}
                >
                  <CustomFont
                    tag="p"
                    className="medium__font t--UPPS"
                    content={link.name}
                  />
                </Link>
              </li>
            )}
          </div>
          <div className="container__right">
            <div className="container__flex">
              {globalData?.informations?.shop?.isActive &&              
                <li
                  key={'shop'}
                  className="link--item"
                >
                  <a
                    href={globalData?.informations?.shop?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="a--LINK"
                    onClick={globalData.handleCloseMenu}
                  >
                    <CustomFont
                      tag="p"
                      className="medium__font t--UPPS"
                      content={'Shop'}
                    />
                  </a>
                </li>
              }
              {secondaryLinks.map((link) => 
                <li
                  key={link.name}
                  className="link--item"
                >
                  <Link
                    to={`/${link.slug}`}
                    className="a--LINK"
                    onClick={globalData.handleCloseMenu}
                  >
                    <CustomFont
                      tag="p"
                      className="medium__font t--UPPS"
                      content={link.name}
                    />
                  </Link>
                </li>
              )}
            </div>
          </div>
        </div>
        {/* <div className="container__bottom">
          <CustomFont
            tag="p"
            className="small__font"
            content={globalData.copyright}
          />
        </div> */}
      </div>
    </motion.div>
  );
};

export default Menu;
