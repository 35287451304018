import React from "react";
// import ReactMarkdown from 'react-markdown';
import CustomFont from "../CustomFont";
import './sectionTitle.scss';

const SectionTitle = ({ className, title, isMain }) => {

  const firstLetter = title && title.split("")[0].charAt(0).toUpperCase()

  return (
    <section className={`section__title custom--${firstLetter} ${isMain === false && 't--MINI'}`}>
      <CustomFont
        tag='h1'
        className={className}
        content={title}
      />
    </section>
  );
};

export default SectionTitle;
