import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { sortedImage } from "../../config/normalize";
import CustomFont from "../CustomFont";
// import ReactMarkdown from 'react-markdown';
import Image from '../Image';
import './sectionImages.scss';

const SectionImages = ({ data }) => {
  const globalData = useContext(DataContext);

  return (
    <section className={`section__images t--MINI--content`}>
      <ul className={`container__images grid--${data.grid}`}>
        {data.medias && data.medias
          .sort((a, b) => sortedImage(a.name) - sortedImage(b.name))
          .map((img) => 
          <li
            key={img.id}
            className="wrapper--img"
          >
            <Image
              src={img.url} // ???
              alt={`${globalData.artistName} - ${data.title}`}
            />
            {img.caption &&
              <CustomFont
                tag="p"
                className="label__font c--GREY caption t--UPPS"
                content={img.caption}
              />
            }
          </li>
        )}
      </ul>
    </section>
  );
};

export default SectionImages;
