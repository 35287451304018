import React, { useEffect, useState, useContext } from 'react';
import DataContext from '../../config/dataContext';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from 'react-helmet';
import CustomFont from '../../components/CustomFont';
import SearchInput from '../../components/SearchInput';
import SectionTitle from '../../components/SectionTitle';
// import WorksToDisplay from '../../components/WorksToDisplay';
import SectionRelatedWorks from '../../components/SectionRelatedWorks';
import SectionLabelContent from '../../components/SectionLabelContent';
import SectionLabelContentPhoto from '../../components/SectionLabelContentPhoto';
import './search.scss';

const Search = ({ works, updates, monographs }) => {
  const globalData = useContext(DataContext);
  const history = useHistory();
  const { queryParams } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [updatesToDisplay, setUpdatesToDisplay] = useState([]);
  const [monographsToDisplay, setMonographsToDisplay] = useState([]);

  useEffect(() => {
    if (queryParams != undefined && globalData.isAllDataLoaded === true) {
      setInputValue(queryParams.split('=')[1])
      let arrSearchParams = queryParams.split('=')[1].split(' ')
      handleFiltering(setWorksToDisplay, arrSearchParams, works)
      handleFiltering(setUpdatesToDisplay, arrSearchParams, updates)
      handleFiltering(setMonographsToDisplay, arrSearchParams, monographs)
    }
  }, [globalData.isAllDataLoaded])

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor('white')
    }
  }, [globalData.isAllDataLoaded])

  useEffect(() => {
    if (queryParams != undefined) {
      let arrSearchParams = queryParams.split('=')[1].split(' ');
      handleFiltering(setWorksToDisplay, arrSearchParams, works)
      handleFiltering(setUpdatesToDisplay, arrSearchParams, updates)
      handleFiltering(setMonographsToDisplay, arrSearchParams, monographs)
    }
  }, [queryParams])

  const handleFiltering = (setFunction, params, data) => {
    let filteredData = data.filter((item) => 
      // This can be upgraded to choose any values of the object 
      params.every((param) => JSON.stringify(item).toLowerCase().includes(param))
    )
    setFunction(filteredData)
  }

  const handleValue = (e) => {
    setInputValue(e.target.value)
  }

  const handleSearch = (e) => {
    if (inputValue.length >= 3 && e.keyCode === 13) {
      history.push(`/search=${inputValue.toLowerCase()}`);
    }
  }

  const handleClickSearch = () => {
    if (inputValue.length >= 3) {
      history.push(`/search=${inputValue.toLowerCase()}`);
    }
  }

  const handleReset = () => {
    history.push('/search');
    setInputValue("");
    setWorksToDisplay([]);
  }

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
      // id='SCROLL--PRESS'
      // onScroll={handleScrollPosition}
    >
      <Helmet>
        <title>{`${globalData.artistName} - Search`}</title>
      </Helmet>
      <div className="container__page SEARCH">
        <SectionTitle
          title={queryParams ? queryParams : "Search"}
          className={`big__font t--CENTER ${queryParams ? 't--CAPS' : 't--UPPS'}`}
          isMain={true}
        />
        <SearchInput
          handleValue={handleValue}
          handleSearch={handleSearch}
          handleClickSearch={handleClickSearch}
          handleReset={handleReset}
          inputValue={inputValue}
        />
        {worksToDisplay.length >= 1 &&
          <>
            <CustomFont
              tag={"h2"}
              className={"medium__font title__section t--CAPS t--MINI--content"}
              content={
                worksToDisplay.length >= 2 ? "projects" : "project"
              }
            />
            <SectionRelatedWorks
              data={worksToDisplay}
              grid={5} // Conditionnal?
            />
          </>
        }
        {updatesToDisplay.length >= 1 &&
          <>
            <CustomFont
              tag={"h2"}
              className={"medium__font title__section t--CAPS t--MINI--content"}
              content={
                updatesToDisplay.length >= 2 ? "more exhibitions" : "more exhibition"
              }
            />
            <SectionLabelContent data={updatesToDisplay} slug="more-exhibitions" />
          </>
        }
        {monographsToDisplay.length >= 1 &&
        <>
          <CustomFont
            tag={"h2"}
            className={"medium__font title__section last t--CAPS t--MINI--content"}
            content={
              monographsToDisplay.length >= 2 ? "monographs" : "monograph"
            }
          />
          <SectionLabelContentPhoto data={monographsToDisplay.filter((d) => d.medias.length >= 1)} slug="monographs" />
        </>
        }
        {queryParams != undefined && worksToDisplay.length <= 0 && updatesToDisplay.length <= 0 &&
          <CustomFont tag="p" className="small__font t--MINI--CONTENT no--results t--UPPS" content="No results" />
        }
      </div>
    </motion.div>
  );
};

export default Search;
