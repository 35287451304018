import React from "react";
import CustomFont from "../CustomFont";
import "./labelContent.scss";

const LabelContent = ({ label, content, isLink }) => {
  return (
    <div className="container__label--content">
      <div className="label">
        <CustomFont
          tag="p"
          className="label__font t--UPPS"
          content={`${label}:`}
        />
      </div>
      <div className="content">
        {isLink === true ? (
          <a
            className="a--LINK"
            href={content}
            target="_blank"
            rel="noopener noreferrer">
              <CustomFont
                tag="p"
                className="label__font t--UPPS"
                content="more"
              />
            </a>
        ) : (
          <CustomFont
            tag="p"
            className="label__font t--UPPS"
            content={content}
          />
        )}
      </div>
    </div>
  );
};

export default LabelContent;
