import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../config/dataContext";
import { motion } from "framer-motion/dist/framer-motion";
import "./startPane.scss";

const StartPane = ({}) => {
  const globalData = useContext(DataContext);
  const colors = [
    {
      name: "blue",
      hexa: "3b82f6",
    },
    {
      name: "orange",
      hexa: "f97316",
    },
    {
      name: "grey",
      hexa: "a3a3a3",
    },
    {
      name: "violet",
      hexa: "8b5cf6",
    },
    {
      name: "red",
      hexa: "ef4444",
    },
    {
      name: "pink",
      hexa: "ec4899",
    },
    {
      name: "yellow",
      hexa: "facc15",
    },
    {
      name: "green",
      hexa: "22c55e",
    },
  ];
  const [activeColor, setActiveColor] = useState({});

  useEffect(() => {
    setActiveColor(colors[Math.floor(Math.random() * colors.length)])
  }, []);

  return (
    <motion.div
      className="wrapper__start--pane"
      style={{
        height: `${globalData.maxHeight + 5}px`,
        width: globalData.maxWidth,
        backgroundColor: `#${activeColor.hexa}`,
      }}
      initial={{
        y: 0,
      }}
      animate={{
        y: globalData.isAllDataLoaded === true ? "-100%" : 0,
      }}
      transition={{
        ease: "easeInOut",
        default: { duration: 0.8 },
        delay: 0
      }}
    >
      <motion.svg
        initial={{
          opacity: 1,
        }}
        animate={{
          opacity: globalData.isAllDataLoaded === true ? 0 : 1,
        }}
        transition={{
          ease: "easeInOut",
          default: { duration: 0.4 },
        }}
        className="spinner" width="250px" height="250px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" strokeWidth="0.5" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </motion.svg>
    </motion.div>
  );
};

export default StartPane;
