import React from 'react';
import './color.scss';

const Color = ({ color, size }) => {

  return (
    <span
      className={`COLOR ${size}`}
      style={{ backgroundColor: `#${color?.hexa}` }}
    ></span>
  );
};

export default Color;