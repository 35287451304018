import React from "react";
import { Link } from "react-router-dom";
import CustomFont from "../CustomFont";
import "./CTA.scss";

const CTA = ({ label, link, isInside, isMailTo, className }) => {
  return (
    <>
    {isInside === false ? (
      <a
        href={isMailTo ? `mailto:${link}` : link}
        target="_blank"
        rel="noopener noreferrer"
        className="CTA a--LINK"
      >
        <CustomFont
          tag="p"
          className={className}
          content={label}
        />
      </a>
    ) : (
      <Link
        to={link}
        className="CTA a--LINK"
      >
        <CustomFont
          tag="p"
          className={className}
          content={label}
        />
      </Link>
    )} 
    </>
  );
};

export default CTA;
