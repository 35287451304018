import React from "react";
import CustomFont from "../CustomFont";
import "./symbolText.scss";

const SymbolText = ({
  className,
  content,
  symbol,
  symbolPosition,
  onClick,
}) => {
  return (
    <div
      className={`container__symbol--text a--LINK`}
      onClick={onClick}
    >
      {symbolPosition === "left" && (
        <CustomFont
          tag="p"
          content={symbol}
          className={`symb symb--pos--${symbolPosition} ${className}`}
        />
      )}
      <CustomFont
        tag="p"
        content={content}
        className={className+ ' text'}
      />
      {symbolPosition === "right" && (
        <CustomFont
          tag="p"
          content={symbol}
          className={`symb symb--pos--${symbolPosition} ${className}`}
        />
      )}
    </div>
  );
};

export default SymbolText;
