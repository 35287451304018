import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Link } from "react-router-dom";
import CustomFont from "../CustomFont";
import { sortedImage, normalizeQuery } from "../../config/normalize";
import Image from "../Image";
import './sectionIndividualWork.scss';

const SectionIndividualWork = ({ id }) => {
  const globalData = useContext(DataContext);
  const work = globalData.works.find((work) => work.id === id)

  console.log(work);

  // const harmonizeDateInt = (int) => {
  //   if (int <= 9) {
  //     return `0${int.toString()}`
  //   } else return int.toString()
  // }

  // const handleYearContent = () => {
  //   if (data.month != null) {
  //     if (data.day != null) {
  //       return `${harmonizeDateInt(data.day)}.${harmonizeDateInt(data.month.id)}.${data.year}`
  //     }
  //     return `${harmonizeDateInt(data.month.name)} ${data.year}`
  //   } else return data.year
  // }

  // const handleYearLabel = () => {
  //   return data.year && data.day != null || data.month != null ? 'date' : 'year'
  // }


  return (
    <section className="section__individual--work t--MINI--content">
      <Link
        to={`/work/${work.id}`}
        // to={`/work/${work.id}=${normalizeQuery(work.title)}`}
        className="a--LINK"
      >
        <div className="container--img">
          <Image
            src={work.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0].formats?.medium.url}
          />
        </div>
        <div className="img--caption">
          <CustomFont
            tag="p"
            className="label__font t--UPPS"
            content={`${work.title}, ${work.year}`}
          />
        </div>
      </Link>
    </section>
  );
};

export default SectionIndividualWork;
