import React, { useEffect, useContext } from 'react';
import SectionTitle from '../../components/SectionTitle';
import DataContext from '../../config/dataContext';
import { Helmet } from 'react-helmet';
import SectionInformations from '../../components/SectionInformations';
import CustomFont from '../../components/CustomFont';
import CustomMarkdown from '../../components/CustomMarkdown';
import './videos.scss';

const Videos = ({ data }) => {
  const globalData = useContext(DataContext);

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor(globalData.informations.page.find((p) => p.page === 'videos').color.name)
    }
  }, [globalData.isAllDataLoaded])

  return (
    <div className="wrapper__page">
      <Helmet>
        <title>{`${globalData.artistName} - Videos`}</title>
      </Helmet>
      <div className="container__page">
        <SectionTitle title='Videos' className='big__font t--UPPS t--CENTER' isMain={true} />
        <section className="wrapper__videos t--MINI--content">
          <ul className="container__videos">
            {data
              .sort((a, b) => b.year - a.year)
              .map((video, i) => 
              <li className="container__video" key={i}>
                <div className="top embed_container">
                  <iframe
                    className="embed_item"
                    // width="100%"
                    // height="auto"
                    src={`https://player.vimeo.com/video/${video.embed_code}`}
                    frameBorder="0"
                    // allow="autoplay; encrypted-media"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullScreen="">
                  </iframe>
                </div>
                <div className="bottom">
                  <div className="left">
                    <div className="container__left">
                      {video.title && 
                        <div className="title">
                          <CustomFont
                            tag='p'
                            className='medium__font'
                            content={video.title}
                          />
                        </div>
                      }
                      <SectionInformations data={video} center={false} />
                    </div>
                  </div>
                  <div className="right">
                    {video.description &&
                      <CustomMarkdown
                        className="medium__font"
                        content={video.description}
                      />
                    }
                  </div>
                </div>
              </li>
            )}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Videos;
