import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import LabelContent from "../LabelContent";
import CustomFont from "../CustomFont";
import './sectionInformations.scss';

const SectionInformations = ({ data, center, isPaddingMini, isLinkPresent, isPublicCollection, isLinks }) => {
  const globalData = useContext(DataContext)

  const harmonizeDateInt = (int) => {
    if (int <= 9) {
      return `0${int.toString()}`
    } else return int.toString()
  }

  const handleYearContent = () => {
    if (data.month != null) {
      if (data.day != null) {
        return `${harmonizeDateInt(data.day)}.${harmonizeDateInt(data.month.id)}.${data.year}`
      }
      // return `${harmonizeDateInt(data.month.name)} ${data.year}`
      return `${globalData.months.find((month) => month.id === data.month.id)?.name} ${data.year}`
    } else return data.year
  }

  const handleYearLabel = () => {
    return data.year && data.day != null || data.month != null ? 'date' : 'year'
  }

  return (
    <section className={`section__informations ${center === true && "is--CENTER t--MINI--content"} ${isPaddingMini && 'padding--MINI'}`}>
      {data.year && <LabelContent label={handleYearLabel()} content={handleYearContent()} />}
      {data.subtitle && <LabelContent label="curation" content={data.subtitle} />}
      {data.period && <LabelContent label="period" content={data.period} />}
      {data.publisher && <LabelContent label="publisher" content={data.publisher} />}
      {data.editor && <LabelContent label="editor" content={data.editor} />}
      {data.author && <LabelContent label="author" content={data.author} />}
      {data.source && <LabelContent label="source" content={data.source} />}
      {data.location && isPublicCollection != true && <LabelContent label="location" content={data.location} />}
      {data.city && <LabelContent label="city" content={data.city} />}
      {isLinkPresent != false && data.link && isLinks != true && <LabelContent label="link" content={data.link} isLink={true} />}
      {isLinks === true && 
        <div className="container__label--content">
          <div className="label">
            <CustomFont
              tag="p"
              className="label__font t--UPPS"
              content={`${data.link && data.file ? 'links' : 'link'}:`}
            />
          </div>
          <div className="content d__flex--label">
            {data.link &&
              <a
                className="a--LINK"
                href={data.link}
                target="_blank"
                rel="noopener noreferrer">
                  <CustomFont
                    tag="p"
                    className="label__font t--UPPS"
                    content="more"
                  />
              </a>
            }
            {data.file &&
              <a
                className="a--LINK"
                href={data.file.url}
                target="_blank"
                rel="noopener noreferrer">
                  <CustomFont
                    tag="p"
                    className="label__font t--UPPS"
                    content="download pdf"
                  />
              </a>
            }
          </div>
        </div>
      }
      {data.embed_code && <LabelContent label="more" content={`https://vimeo.com/${data.embed_code}`} isLink={true} />}
    </section>
  );
};

export default SectionInformations;
