import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Link } from "react-router-dom";
import { normalizeQuery } from "../../config/normalize";
import SectionInformations from "../SectionInformations";
import CustomFont from "../CustomFont";
import './sectionLabelContent.scss';

const SectionLabelContent = ({ data, slug, isPublicCollection, noLink }) => {
  const globalData = useContext(DataContext)

  return (
    <section className="section__label--content t--MINI--content">
      <ul className="container__content">
        {data
          .sort((a, b) => b.month?.id - a.month?.id)
          .sort((a, b) => b.year - a.year)
          .map((el, i) =>
            <li className="item" key={i}>
              <div className="el--item">
                {noLink === true ? 
                  <div className="title">                    
                    <CustomFont
                      tag='p'
                      className='medium__font'
                      content={el.title}
                    />
                  </div>
                 : 
                  el.title &&
                    <div className="title">
                      <Link
                        to={`/${slug}/${el.id}=${normalizeQuery(el.title)}`}
                        onClick={globalData.handleCloseMenu}
                        className="a--LINK"
                      >                      
                        <CustomFont
                          tag='p'
                          className='medium__font'
                          content={el.title}
                        />
                      </Link>
                    </div>
                }
                {isPublicCollection && el.location &&
                  <div className="title">
                    <CustomFont
                      tag='p'
                      className='medium__font'
                      content={el.location}
                    />
                  </div>
                }
                <SectionInformations data={el} center={false} isPublicCollection={isPublicCollection} />
              </div>
            </li>
        )}
      </ul>
    </section>
  );
};

export default SectionLabelContent;
