import React, { useContext } from "react";
// import LabelContent from "../LabelContent";
import DataContext from "../../config/dataContext";
import CustomFont from "../CustomFont";
import TagColor from "../TagColor";
import './sectionMainInformations.scss';

const SectionMainInformations = ({ data, isFull }) => {
  const globalData = useContext(DataContext)

  // const harmonizeDateInt = (int) => {
  //   if (int <= 9) {
  //     return `0${int.toString()}`
  //   } else return int.toString()
  // }

  // const handleYearContent = () => {
  //   if (data.month != null) {
  //     if (data.day != null) {
  //       return `${harmonizeDateInt(data.day)}.${harmonizeDateInt(data.month.id)}.${data.year}`
  //     }
  //     return `${harmonizeDateInt(data.month.name)} ${data.year}`
  //   } else return data.year
  // }

  // const handleYearLabel = () => {
  //   return data.year && data.day != null || data.month != null ? 'date' : 'year'
  // }

  const hightlightWork = globalData.works.find((work) => work.id === data.id)

  // console.log(hightlightWork);
  // console.log(globalData.tags)

  return (
    <section className="section__main--informations t--MINI--content">
      {hightlightWork?.year &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.period ? data.period : data.year}
            className="small__font t--CAPS"
          />
        </div>
      }
      {isFull &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.title}
            className="small__font t--CAPS"
          />
        </div>
      }
      {/* {hightlightWork?.tags?.length >= 1 &&
        hightlightWork.tags
          .sort((a, b) => a.order - b.order)
          .map((el, i) => (
            <div className="main--info" key={i}>
              <TagColor
                size={"small"}
                // tag={tag.id === undefined ? (
                //   globalData.tags.find((tag) => tag.id === data.tag)
                // ) : (
                //   globalData.tags.find((tag) => tag.id === tag.tag_id)
                // )}
                tag={globalData.tags.find((tag) => tag.id === el.tag_id)}
              />
            </div>
        ))
      } */}
      {hightlightWork?.tags.length >= 1 &&
        <div className="main--info tags--container">
          {hightlightWork?.tags
            .sort((a, b) => a.order - b.order)
            .map((el, i) =>
              <TagColor
                size={"small"}
                tag={globalData.tags.find((tag) => tag.id === el.tag_id)}
                key={i}
              />
            )}
        </div>
      }
      {hightlightWork?.materials &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.materials}
            className="small__font t--CAPS"
          />
        </div>
      }
      {hightlightWork?.dimensions &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={`${data.dimensions} inches`}
            className="small__font"
          />
        </div>
      }
      {hightlightWork?.location &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.location}
            className="small__font"
          />
        </div>
      }
      {hightlightWork?.city &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.city}
            className="small__font t--CAPS"
          />
        </div>
      }
    </section>
  );
};

export default SectionMainInformations;
