import React, { useEffect, useContext, useState } from "react";
import DataContext from '../../config/dataContext';
import { Switch, Route } from 'react-router-dom';
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from 'react-helmet';
import SectionTitle from "../../components/SectionTitle";
import NavbarBiography from "../../components/NavbarBiography";
// import SectionContent from "../../components/SectionContent";
// import SectionMainInformations from "../../components/SectionMainInformations";
// import Image from "../../components/Image";
// import { sortedImage, normalizeQuery } from '../../config/normalize';
// import SectionCTA from '../../components/SectionCTA';
// import { Link } from "react-router-dom";
// import BioExhibitions from "../../components/BioExhibitions";
import SectionLabelContent from "../../components/SectionLabelContent";
import SectionGroupPublications from "../../components/SectionGroupPublications";
import './biography.scss';

const Biography = () => {
  const globalData = useContext(DataContext);
  const navLinks = [
    {name: "solo and two person exhibitions", slug: "solo-and-two-person-exhibitions"},
    {name: "group exhibitions", slug: "group-exhibitions"},
    // {name: "updates", slug: "updates"},
    {name: "public collections", slug: "public-collections"},
    {name: "awards", slug: "awards"},
    {name: "group publications", slug: "group-publications"},
  ]

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor(globalData.informations.page.find((p) => p.page === 'biography').color.name)
    }
  }, [globalData.isAllDataLoaded])


  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
      // id='SCROLL--PRESS'
      // onScroll={handleScrollPosition}
    >
      <Helmet>
        <title>{`${globalData.artistName} - Biography`}</title>
      </Helmet>
      <div className="container__page">
        <SectionTitle title='biography' className='big__font t--UPPS t--CENTER' isMain={true} />
        <NavbarBiography navLinks={navLinks}/>
        <div className="wrapper__switch--biography t--MINI--content">
          <Switch>
            <Route path="/biography/solo-and-two-person-exhibitions" render={() => <SectionLabelContent data={globalData.soloExhibitions} title="Solo and two person exhibitions" noLink={true} />} />
            <Route path="/biography/group-exhibitions" render={() => <SectionLabelContent data={globalData.groupExhibitions} title="Group exhibitions" noLink={true} />} />
            {/* <Route path="/biography/updates" render={() => <SectionLabelContent data={globalData.updates} title="Updates" />} /> */}
            <Route path="/biography/public-collections" render={() => <SectionLabelContent data={globalData.publicCollections} title="Public Collections" isPublicCollection={true} />} />
            <Route path="/biography/awards" render={() => <SectionLabelContent data={globalData.awards} title="Awards" noLink={true} />} />
            <Route path="/biography/group-publications" render={() => <SectionGroupPublications data={globalData.groupPublications} />} />
          </Switch>
        </div>
      </div>
    </motion.div>
  )
};

export default Biography;