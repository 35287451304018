import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import CustomFont from '../CustomFont';
// import { normalizeQuery } from '../../config/normalize';
import Color from '../Color';
import './tagColor.scss';

const TagColor = ({ tag, size, isS }) => {
  const globalData = useContext(DataContext)

  return (
    <div
      className="TAG--COLOR"
      // data-filter={normalizeQuery(data.name)}
      // data-is-active={data.isActive}
      // style={{
      //   border: `1px solid #${globalData.colors.find((color) => color.id === tag?.color.id)?.hexa}`
      // }}
      data-color={globalData.colors.find((color) => color.id === tag?.color.id).name}
      data-name={tag?.name}
    >
      <Color color={globalData.colors.find((color) => color.id === tag?.color.id)} size={size} />
      <CustomFont
        tag={"span"}
        className={`${size}__font t--UPPS`}
        content={isS ? (
          tag?.name_s ? tag?.name_s : tag?.name
        ): (
          tag?.name
        )}
      />
    </div>
  );
};

export default TagColor;