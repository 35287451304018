import React, { useContext } from 'react';
import DataContext from '../../config/dataContext';
import CustomFont from '../CustomFont';
import { sortedImage } from '../../config/normalize';
import Image from '../Image';
import TagColor from '../TagColor';
import './thumbnailWork.scss';

const ThumbnailWork = ({ data, grid }) => {
  const globalData = useContext(DataContext)

  return (
    <div className="container__thumbnail--work">
      {data.medias?.length >= 1 &&
        <Image
          src={grid >= 2 ?
            data.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0]?.formats?.large?.url ?
              data.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0]?.formats?.large?.url
              :
              data.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0].url
            :
            data.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0].url
          }
          alt={`${globalData.artistName} - ${data.title}`}
        />
      }
      {data.year &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.period ? data.period : data.year}
            className="small__font t--CAPS"
          />
        </div>
      }
      {data.title &&
        <div className="main--info">
          <CustomFont
            tag="p"
            content={data.title}
            className="small__font t--CAPS"
            />
        </div>
      }
      {data.tags.length >= 1 &&
        <div className="main--info tags--container">
          {data.tags
            .sort((a, b) => a.order - b.order)
            .map((el, i) =>
              <TagColor
                size={"label"}
                tag={globalData.tags.find((tag) => tag.id === el.tag_id)}
                key={i}
              />
            )}
        </div>
      }
    </div>
  );
};

export default ThumbnailWork;