import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { fetchData } from "./config/fetchData";
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import DataContext from "./config/dataContext";
import Header from "./components/Header";
// import Home from "./Pages/Home";
import Works from "./Pages/Works";
import Work from "./Pages/Work";
import ScrollMemory from 'react-router-scroll-memory';
import Contact from "./Pages/Contact";
import Search from "./Pages/Search";
import Error404 from "./Pages/Error404";
import Imprint from "./Pages/Imprint";
import About from "./Pages/About";
import Bibliographies from "./Pages/Bibliographies";
import Bibliography from "./Pages/Bibliography";
import Menu from "./components/Menu";
import Biography from "./Pages/Biography";
import Updates from "./Pages/Updates";
import Update from "./Pages/Update";
import StartPane from "./components/StartPane";
import Publications from "./Pages/Publications";
import Publication from "./Pages/Publication";
import Videos from "./Pages/Videos";
import "./styles/reset.scss";

const App = () => {
  const artistName = "Daniel Gordon"
  // const location = useLocation()
  const [works, setWorks] = useState([])
  const [isWorksIsLoaded, setIsWorksLoaded] = useState(false)
  const [tags, setTags] = useState([])
  const [isTagsLoaded, setIsTagsLoaded] = useState(false)
  const [bibliographies, setBibliographies] = useState([])
  const [isBibliographiesLoaded, setIsBibliographiesLoaded] = useState(false)
  const [colors, setColors] = useState([])
  const [isColorsLoaded, setIsColorsLoaded] = useState(false)
  const [months, setMonths] = useState([])
  const [isMonthsLoaded, setIsMonthsLoaded] = useState(false)

  const [soloExhibitions, setSoloExhibitions] = useState([]);
  const [isSoloExhibitionsLoaded, setIsSoloExhibitionsLoaded] = useState(false);
  const [groupExhibitions, setGroupExhibitions] = useState([]);
  const [isGroupExhibitionsLoaded, setIsGroupExhibitionsLoaded] = useState(false);
  const [publicCollections, setPublicCollections] = useState([]);
  const [isPublicCollectionsLoaded, setIsPublicCollectionsLoaded] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [isUpdatesLoaded, setIsUpdatesLoaded] = useState(false);
  const [awards, setAwards] = useState([]);
  const [isAwardsLoaded, setIsAwardsLoaded] = useState(false);

  const [monographs, setMonographs] = useState([]);
  const [isMonographsLoaded, setIsMonographsLoaded] = useState(false);
  const [groupPublications, setGroupPublications] = useState([]);
  const [isGroupPublicationsLoaded, setIsGroupPublicationsLoaded] = useState(false);

  const [videos, setVideos] = useState([]);
  const [isVideosLoaded, setIsVideosLoaded] = useState(false);

  const [imprint, setImprint] = useState({})
  const [isImprintLoaded, setIsImprintLoaded] = useState(false)
  const [informations, setInformations] = useState({})
  const [isInformationsLoaded, setIsInformationsLoaded] = useState(false)
  const [galeries, setGaleries] = useState([])
  const [isGaleriesLoaded, setIsGaleriesLoaded] = useState(false)
  
  const [currentColor, setCurrentColor] = useState({})
  const [currentMenuColor, setCurrentMenuColor] = useState({})
  const [isLogoCentered, setIsLogoCentered] = useState(true)
  const [isMenuActive, setIsMenuActive] = useState(false)

  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false)

  // const [scrollPositionPress, setScrollPositionPress] = useState(0);

  // const handleScrollPosition = (setFunction, el) => {
  //   setFunction(el.scrollTop)
  // }

  // const handleScrollPress = (v) => {
  //   setScrollPositionPress(v)
  // }

  const [maxWidth, setMaxWidth] = useState()
  const [maxHeight, setMaxHeight] = useState()

  const currentYear = new Date().getFullYear()
  const copyright = `All rights reserved © Daniel Gordon Studio ${currentYear}`
  const mobileWidth = 768

  window.addEventListener('resize', function(){
    // setIsMenuActive(false)
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  });

  useEffect(() => {
    fetchData(setWorks, "works", setIsWorksLoaded);
    fetchData(setTags, "tags", setIsTagsLoaded);
    fetchData(setBibliographies, "bibliographies", setIsBibliographiesLoaded);

    fetchData(setSoloExhibitions, "solo-exhibitions", setIsSoloExhibitionsLoaded);
    fetchData(setGroupExhibitions, "group-exhibitions", setIsGroupExhibitionsLoaded);
    fetchData(setPublicCollections, "public-collections", setIsPublicCollectionsLoaded);
    fetchData(setUpdates, "updates", setIsUpdatesLoaded);
    fetchData(setAwards, "awards", setIsAwardsLoaded);

    fetchData(setMonographs, "publications", setIsMonographsLoaded);
    fetchData(setGroupPublications, "group-publications", setIsGroupPublicationsLoaded);

    fetchData(setVideos, "videos", setIsVideosLoaded);

    fetchData(setColors, "colors", setIsColorsLoaded);
    fetchData(setMonths, "months", setIsMonthsLoaded);
    fetchData(setInformations, "informations", setIsInformationsLoaded);
    fetchData(setGaleries, "galleries", setIsGaleriesLoaded);
    fetchData(setImprint, "imprint", setIsImprintLoaded);
    setMaxHeight(window.innerHeight)
    setMaxWidth(window.innerWidth)
  }, []);

  useEffect(() => {
    if (
      isWorksIsLoaded === true &&
      isTagsLoaded === true &&
      isBibliographiesLoaded &&

      isSoloExhibitionsLoaded === true &&
      isGroupExhibitionsLoaded === true &&
      isPublicCollectionsLoaded === true &&
      isUpdatesLoaded === true &&
      isAwardsLoaded === true &&

      isMonographsLoaded === true &&
      isGroupPublicationsLoaded === true &&

      isVideosLoaded === true &&

      isColorsLoaded &&
      isMonthsLoaded &&
      isInformationsLoaded &&
      isGaleriesLoaded &&
      isImprintLoaded
      ) {
        setIsAllDataLoaded(true)
      }
  }, [
    isWorksIsLoaded,
    isTagsLoaded,
    isBibliographiesLoaded,

    isSoloExhibitionsLoaded,
    isGroupExhibitionsLoaded,
    isPublicCollectionsLoaded,
    isUpdatesLoaded,
    isAwardsLoaded,

    isMonographsLoaded,
    isGroupPublicationsLoaded,

    isVideosLoaded,

    isColorsLoaded,
    isMonthsLoaded,
    isInformationsLoaded,
    isGaleriesLoaded,
    isImprintLoaded
  ]);

  // useEffect(() => {
  //   if (isAllDataLoaded === true) {
  //     setCurrentMenuColor(colors[Math.floor(Math.random() * colors.length)])
  //   }
  // }, [isAllDataLoaded])

  useEffect(() => {
    if (isMenuActive === true && isAllDataLoaded === true) {
      setCurrentMenuColor(colors[Math.floor(Math.random() * colors.length)])
    } else {
      setCurrentMenuColor({})
    }
  }, [isAllDataLoaded, isMenuActive])

  // PICK NEW COLOR IF SAME
  // WITHOUT WHITE ===> OK
  const handleCurrentColor = (c) => {
    if (c != undefined) {
      setCurrentColor(colors.find((color) => color.name === c))
    } else {
      let color = colors.filter((color) => color.name != 'white') [Math.floor(Math.random() * colors.length - 1)]
      if (color.name != currentColor.name) {
        setCurrentColor(color)
      }
    }
  }

  const handleCenterLogo = (value) => {
    setIsLogoCentered(value)
  }

  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }

  const handleCloseMenu = () => {
    setIsMenuActive(false)
  }

  return (
    <DataContext.Provider
      value={{
        artistName: artistName,
        works: works,
        tags: tags,
        bibliographies: bibliographies,

        soloExhibitions: soloExhibitions,
        groupExhibitions: groupExhibitions,
        publicCollections: publicCollections,
        updates: updates,
        awards: awards,

        monographs: monographs,
        groupPublications: groupPublications,

        videos: videos,

        colors: colors,
        months: months,
        isAllDataLoaded: isAllDataLoaded,
        isLogoCentered: isLogoCentered,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        handleCenterLogo: handleCenterLogo,
        handleCurrentColor: handleCurrentColor,
        handleMenu: handleMenu,
        handleCloseMenu: handleCloseMenu,
        isMenuActive: isMenuActive,
        currentMenuColor: currentMenuColor,
        // handleScrollPosition: handleScrollPosition,
        // scrollPositionPress: scrollPositionPress,
        // handleScrollPress: handleScrollPress,
        copyright: copyright,
        mobileWidth: mobileWidth,
        informations: informations,
        imprint: imprint,
        galeries: galeries,
        currentYear: currentYear
      }}
    >
      <Router>
        <div className="wrapper__application">
          <ScrollMemory />
          {/* <div id="APP"> */}
            <StartPane />
            <div className="container__application">
              <Header />
              <div className="wrapper__switch--main">
                <AnimatePresence>
                  <Switch>
                {/* <AnimatePresence exitBeforeEnter={false} initial={false}>
                  <Switch location={location} key={location.pathname}> */}

                    {/* <Route path="/" exact render={() => <Home />} /> */}
                    <Route path="/" exact render={() => <Redirect to="/index&type=1&sortBy=type" />} />

                    {/* <Route path="/" exact render={() => <Home />} /> */}
                    {/* <Route path="/works" exact render={() => <Works works={works} tags={tags} />} />
                    <Route path="/works:queryParams" render={() => <Works works={works} tags={tags} />} /> */}
                    <Route path="/work/:queryParams" render={() => <Work works={works} />} />

                    <Route path="/index" render={() => <Redirect to="/index&type=*&sortBy=year" />} />
                    <Route path="/index&type=:typeID&sortBy=:sortBy" render={() => <Works works={works} tags={tags} />} />
                    {/* <Route path="/index:queryParams" render={() => <Works works={works} tags={tags} />} /> */}

                    <Route path="/biography" render={() => <Biography />} />
                    {/* <Route path="/biography" render={() => <Redirect to="/biography/solo-exhibitions" />} /> */}

                    <Route path="/press" exact render={() => <Bibliographies data={bibliographies} />} />
                    <Route path="/press/:queryParams" render={() => <Bibliography data={bibliographies} />} />

                    <Route path="/more-exhibitions" exact render={() => <Updates data={updates} />} />
                    <Route path="/more-exhibitions/:queryParams" render={() => <Update data={updates} />} />

                    <Route path="/monographs" exact render={() => <Publications data={monographs} />} />
                    <Route path="/monographs/:queryParams" render={() => <Publication data={monographs} />} />

                    <Route path="/videos" render={() => <Videos data={videos} />} />

                    <Route path="/contact" render={() => <Contact />} />
                    <Route path="/imprint" render={() => <Imprint />} />
                    <Route path="/about" render={() => <About />} />
                    <Route path="/search" exact render={() => <Search works={works} updates={updates} monographs={monographs} />} />
                    <Route path="/search:queryParams" render={() => <Search works={works} updates={updates} monographs={monographs} />} />
                    <Route path="*" render={() => <Error404 />} />
                  </Switch>
                </AnimatePresence>
              </div>
              <Menu />
            </div>
            <motion.div
              className="container__COLOR--fixed"
              animate={{
                backgroundColor: `#${currentColor.hexa}`
              }}
              transition={{
                ease: "easeInOut",
                default: { duration: 0.6 },
              }}
              data-color={currentColor.name}
              style={{
                height: maxHeight
              }}
            ></motion.div>
          </div>
        {/* </div> */}
      </Router>
    </DataContext.Provider>
  );
};

// ReactDOM.render(<App />, document.getElementById("root"));
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);