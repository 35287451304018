import React from "react";
import CustomMarkdown from "../CustomMarkdown";
import './sectionContent.scss';

const SectionContent = ({ data }) => {

  return (
    <section className="section__content t--MINI--content">
      <CustomMarkdown
        className="medium__font"
        content={data}
      />
    </section>
  );
};

export default SectionContent;
