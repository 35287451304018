import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Link } from "react-router-dom";
import { normalizeQuery } from "../../config/normalize";
import SectionInformations from "../SectionInformations";
import CustomFont from "../CustomFont";
import Image from "../Image";
import './sectionGroupPublications.scss';

const SectionGroupPublications = ({ data }) => {
  const globalData = useContext(DataContext)

  // const harmonizeDateInt = (int) => {
  //   if (int <= 9) {
  //     return `0${int.toString()}`
  //   } else return int.toString()
  // }

  return (
    <section className="section__group--publication t--MINI--content">
      <ul className="container__content">
        {data
          .sort((a, b) => b.month?.id - a.month?.id)
          .sort((a, b) => b.year - a.year)
          .map((el) =>
            <li className="item" key={el.id}>
              <div className="el--thumbnail">
                {el.thumbnail ? (
                  <Image
                    src={el.thumbnail.url}
                  />
                ) : (
                  <div className="THUMBNAIL">
                    <CustomFont
                      tag="p"
                      className="label__font t--UPPS"
                      content="THUMBNAIL"
                    />
                  </div>
                )}
              </div>
              <div className="el--item">
                {el.title &&
                  <div className="title">
                    <CustomFont
                      tag='p'
                      className='medium__font'
                      content={el.title}
                    />
                  </div>
                }
                <SectionInformations data={el} center={false} isLinks={true} />
              </div>
            </li>
        )}
      </ul>
    </section>
  );
};

export default SectionGroupPublications;
