import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Link } from "react-router-dom";
import { normalizeQuery } from "../../config/normalize";
import ThumbnailWork from "../ThumbnailWork";
import CustomFont from "../CustomFont";
import './sectionRelatedWorks.scss';

const SectionRelatedWorks = ({ title, data, grid }) => {
  const globalData = useContext(DataContext);

  return (
    <section className="section__related--works t--MINI--content">
      {title &&      
        <CustomFont
          tag="p"
          className="medium__font t--CAPS title"
          content={title}
        />
      }
      <ul className={`container__related--works grid--${grid ? grid : 4}`}>
        {data && data
          .sort((a, b) => a.title.localeCompare(b.title))
          .sort((a, b) => b.year - a.year)
          .map((work) => 
            <li
              key={work.id}
              className="wrapper__related--work"
            >
              <Link
                to={`/work/${work.id}`}
                // to={`/work/${work.id}=${normalizeQuery(work.title)}`}
                className="a--LINK"
              >
                <ThumbnailWork data={work} grid={grid} />
              </Link>
            </li>
        )}
      </ul>
    </section>
  );
};

export default SectionRelatedWorks;
