import React, { useContext } from "react";
import DataContext from "../../config/dataContext";
import { Link } from "react-router-dom";
import { normalizeQuery } from "../../config/normalize";
import SectionInformations from "../SectionInformations";
import { sortedImage } from "../../config/normalize";
import CustomFont from "../CustomFont";
import './sectionLabelContentPhoto.scss';
import Image from "../Image";

const SectionLabelContentPhoto = ({ data, slug }) => {
  const globalData = useContext(DataContext)

  return (
    <section className="section__label--content t--MINI--content monographs">
      <ul className="container__content">
        {data
          .sort((a, b) => b.month?.id - a.month?.id)
          .sort((a, b) => b.year - a.year)
          .map((el) =>
            <li className="item" key={el.id}>
              <Link
                to={`/${slug}/${el.id}=${normalizeQuery(el.title)}`}
                onClick={globalData.handleCloseMenu}
                className="a--LINK item"
              >    
                {el.medias.length >= 1 &&
                  <div className="img--container">
                    <Image
                      src={el.medias.sort((a, b) => sortedImage(a.name) - sortedImage(b.name))[0].url}
                    />
                  </div>
                }
                <div className="el--item">
                  {el.title &&
                    <div className="title">                  
                        <CustomFont
                          tag='p'
                          className='medium__font'
                          content={el.title}
                        />
                    </div>
                  }
                  <SectionInformations data={el} center={true} isPaddingMini={true} isLinkPresent={false} />
                </div>
              </Link>
            </li>
        )}
      </ul>
    </section>
  );
};

export default SectionLabelContentPhoto;
