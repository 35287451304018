import React from "react";
import CTA from "../CTA";
// import ReactMarkdown from 'react-markdown';
import './sectionCTA.scss';

const SectionCTA = ({ data, isError, isHome }) => {

  return (
    <section className="section__CTA t--MINI--content">
      {data &&
        <>
          {data.link && <CTA label="more" link={data.link} isInside={false}  className="small__font t--UPPS"/>}
          {data.file && <CTA label="download pdf" link={data.file.url} isInside={false}  className="small__font t--UPPS"/>}
          {data.mail && <CTA label="mail" link={data.mail} isInside={false} isMailTo={true}  className="small__font t--UPPS"/>}
          {data.instagram && <CTA label="instagram" link={data.instagram} isInside={false}  className="small__font t--UPPS"/>}
          {data.cv && <CTA label="cv" link={data.cv.url} isInside={false}  className="small__font t--UPPS"/>}
        </>  
      }
      {isError &&
        <>
          <CTA label="index" link={"/"} isInside={true} className="small__font t--UPPS"/>
          <CTA label="contact" link={"/contact"} isInside={true} className="small__font t--UPPS"/>
          <CTA label="imprint" link={"/imprint"} isInside={true} className="small__font t--UPPS"/>
        </>
      }
      {isHome &&
        <>
          <CTA label="all exhibitions" link={"/index&type=1&sortBy=type"} isInside={true} className="small__font t--UPPS"/>
          <CTA label="more works" link={"/index"} isInside={true} className="small__font t--UPPS"/>
        </>
      }
    </section>
  );
};

export default SectionCTA;
