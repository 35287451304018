import React, { useEffect, useContext, useState }  from 'react';
import { useParams } from 'react-router';
// import CustomMarkdown from '../../components/CustomMarkdown';
import SectionTitle from '../../components/SectionTitle';
import DataContext from '../../config/dataContext';
import { motion } from "framer-motion/dist/framer-motion";
import SectionContent from '../../components/SectionContent';
import SectionInformations from '../../components/SectionInformations';
import SectionCTA from '../../components/SectionCTA';
import SectionImages from '../../components/SectionImages';
import { Helmet } from 'react-helmet';
import './update.scss';

const Update = ({ data }) => {
  const globalData = useContext(DataContext)
  const { queryParams } = useParams()
  const paramId = parseInt(queryParams.split("=")[0], 10);
  const [bibliography, setBibliography] = useState({});

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor('white')
    }
  }, [globalData.isAllDataLoaded])

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      setBibliography(data.find((el) => el.id === paramId))
    }
  }, [globalData.isAllDataLoaded, queryParams])

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <Helmet>
        <title>{`${globalData.artistName} - ${bibliography.title}`}</title>
      </Helmet>
      <div className="container__page container__publication">
        <SectionTitle title={bibliography.title} className='big__font t--CAPS t--CENTER' isMain={false} />
        <SectionInformations data={bibliography} center={true} />
        {bibliography.medias && bibliography.medias.length >= 1 &&
          <SectionImages data={bibliography} />
        }
        <SectionContent data={bibliography.description} />
        <SectionCTA data={bibliography} />
      </div>
    </motion.div>
  );
};

export default Update;
