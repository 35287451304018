import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import DataContext from "../../config/dataContext";
// import CustomFont from "../../components/CustomFont";
// import Image from "../../components/Image";
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from 'react-helmet';
import { normalizeQuery, sortedImage } from "../../config/normalize";
import CTA from "../../components/CTA";
import SectionTitle from "../../components/SectionTitle";
import SectionMainInformations from "../../components/SectionMainInformations";
import SectionImages from "../../components/SectionImages";
import SectionContent from "../../components/SectionContent";
import SectionRelatedWorks from "../../components/SectionRelatedWorks";
import './work.scss';

const Work = ({ works }) => {
  const { queryParams } = useParams();
  const globalData = useContext(DataContext);
  // const history = useHistory();
  const [work, setWork] = useState({});
  // const paramId = parseInt(queryParams.split("=")[0], 10);
  // const paramTitle = queryParams.split("=")[1];
  const [individualWorks, setIndividualWorks] = useState([]);
  const [extraWorks, setExtraWorks] = useState([]);
  const [uniqExtraWorks, setUniqExtraWorks] = useState([]);

  const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor('white')
      let temporaryWork = works.find(work => work.id == queryParams)
      setWork(temporaryWork);
      // if (temporaryWork.id === paramId && normalizeQuery(temporaryWork.title) == normalizeQuery(paramTitle)) {
      //   setWork(temporaryWork);
      // } else {
      //   history.push("/error404");
      // }
    }
  }, [globalData.isAllDataLoaded, queryParams]);

  useEffect(() => {
    let arr = []
    work.linked_works && work.linked_works.map((linkedWork) => {
      arr.push(globalData.works.find((work) => work.id === linkedWork.related_work_id))
    })
    setIndividualWorks(arr)
  }, [work])

  // const setRW = (arr) => {
  //   let x = []
  //   arr && arr.map((lw) => {
  //     x.push(globalData.works.find((w) => w.id === lw.related_work_id))
  //   })
  //   return x
  // }

  useEffect(() => {
    setUniqExtraWorks(uniqueArray(extraWorks))
  }, [extraWorks])

  useEffect(() => {
    setExtraWorks([])
    if (globalData.isAllDataLoaded === true) {
    // if (work.tag?.order != 1 && globalData.isAllDataLoaded === true) {
      work.linked_works?.map((linkedWork) => {
        let exhibition = globalData.works.find((work) => work.id === linkedWork.related_work_id)
        exhibition.linked_works.map((linkedWork) => {
          let element = globalData.works.find((work) => work.id === linkedWork.related_work_id)
          if (element.id !== work.id) {
            // setExtraWorks(extraWorks => [...extraWorks, {related_work_id: element.id, year: element.year}])
            setExtraWorks(extraWorks => [...extraWorks, globalData.works.find((work) => work.id === element.id)])
          }
        })
      })
    }
  }, [globalData.isAllDataLoaded, work]);

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
      // id='SCROLL--PRESS'
      // onScroll={handleScrollPosition}
    >
      <Helmet>
        <title>{`${globalData.artistName} - ${work.title}`}</title>
      </Helmet>
      <div className="container__work">
        <SectionTitle title={work.title} className='big__font t--CENTER' isMain={false} />
        <SectionMainInformations data={work} />
        {work.medias && work.medias.length >= 1 &&
          <SectionImages data={work} />
        }
        {work.description &&        
          <SectionContent data={work.description} />
        }
        {work.link &&
          <div className="container--more t--MINI--content">
            <CTA label="more"
              link={work.link}
              isInside={false}
              isMailTo={false}
              className="medium__font t--CAPS"
            />
        </div>
        }
        {individualWorks.length >= 1 &&
          <SectionRelatedWorks
            // title={"{Primary items}-{grid-2}"}
            // title={work.tags[0]?.name === "Exhibition" ||
            //   work.tags[1]?.name === "Exhibition" ||
            //   work.tags[2]?.name === "Exhibition" ||
            //   work.tags[0]?.name === "Art fair" ||
            //   work.tags[1]?.name === "Art fair" ||
            //   work.tags[2]?.name === "Art fair"
            //   ? individualWorks.length <= 1 ? "Related work" : "Related works"
            //   : individualWorks.length <= 1 ? "Related Exhibition" : "Related Exhibitions"
            // }
            // data={work.tags[0]?.name === "Exhibition" ||
            // work.tags[1]?.name === "Exhibition" ||
            // work.tags[2]?.name === "Exhibition" ||
            // work.tags[0]?.name === "Art fair" ||
            // work.tags[1]?.name === "Art fair" ||
            // work.tags[2]?.name === "Art fair"
            // ? individualWorks // MAYBE?
            // : individualWorks.filter((w) => w.tags[0]?.name === "Exhibition" || w.tags[1]?.name === "Exhibition"|| w.tags[2]?.name === "Exhibition" || w.tags[0]?.name === "Art fair" || w.tags[1]?.name === "Art fair"|| w.tags[2]?.name === "Art fair")}
            data={individualWorks}
            grid={work.tags[0]?.name === "Exhibition" ||
            work.tags[1]?.name === "Exhibition" ||
            work.tags[2]?.name === "Exhibition" ||
            work.tags[0]?.name === "Art fair" ||
            work.tags[1]?.name === "Art fair" ||
            work.tags[2]?.name === "Art fair"
            ? 5 : 2}
          />
        }
        {uniqExtraWorks.length >= 1 &&
          work.tags[0]?.name != "Exhibition" &&
          work.tags[1]?.name != "Exhibition" &&
          work.tags[2]?.name != "Exhibition" &&
          work.tags[0]?.name != "Art fair" &&
          work.tags[1]?.name != "Art fair" &&
          work.tags[2]?.name != "Art fair" && 
          <SectionRelatedWorks
            // title={uniqExtraWorks.length <= 1 ? "Related work" : "Related works"}
            // title={"{Secondary items}-{grid-5}"}
            data={uniqExtraWorks}
            grid={5}
          />
        }
      </div>
    </motion.div>
  )
};

export default Work;