import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import DataContext from '../../config/dataContext';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion/dist/framer-motion";
import FilterTags from '../../components/FilterTags';
import FilterTHead from '../../components/FilterTHead';
import SectionTitle from '../../components/SectionTitle';
// import WorksToDisplay from '../../components/WorksToDisplay';
import SectionRelatedWorks from '../../components/SectionRelatedWorks';
import './works.scss';

const Works = ({ works, tags }) => {
  const globalData = useContext(DataContext);
  // const isFilterMultiple = true;
  // const { queryParams } = useParams();
  const { sortBy } = useParams();
  const { typeID } = useParams();
  const history = useHistory();
  // const prevTagsQuery = "&tags="
  // const prevTHeadQuery = "&filter="
  const [filterTags, setFilterTags] = useState([]);
  // const [activeTagIDs, setActiveTagIDs] = useState([]);
  const [worksToDisplay, setWorksToDisplay] = useState([]);
  const [filterTHead, setFilterTHead] = useState([
    {name: "year", isActive: false},
    {name: "type", isActive: true},
    {name: "title", isActive: false},
  ])

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor('white')
    }
  }, [globalData.isAllDataLoaded])

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      setFilterTags(tags.map((tag) => {return {...tag, isActive: false}}))
      if (typeID != '*' && sortBy == 'title') {
        setWorksToDisplay(sortedWorks(works)
          .filter((work) => work.tag.order == typeID) // !!!! WARNING
          .sort((a, b) => a.number - b.number)
          .sort((a, b) => a.title.localeCompare(b.title))
        )
      } else if (typeID != '*' && sortBy == 'year') {
        setWorksToDisplay(sortedWorks(works)
          .filter((work) => work.tag.order == typeID) // !!!! WARNING
          .sort((a, b) => b.year - a.year)
        )
      } else if (typeID != '*' && sortBy == 'type') {
        setWorksToDisplay(sortedWorks(works)
          // .filter((work) => work.tag.order == typeID) // !!!! WARNING
          .filter((work) =>
            work.tags[0]?.order == typeID ||
            work.tags[1]?.order == typeID ||
            work.tags[2]?.order == typeID
          )
        )
      } else if (typeID == '*' && sortBy == 'title') {
        setWorksToDisplay(sortedWorks(works)
          .sort((a, b) => a.number - b.number)
          .sort((a, b) => a.title.localeCompare(b.title))
        )
      } else if (typeID == '*' && sortBy == 'year') {
        setWorksToDisplay(sortedWorks(works)
          .sort((a, b) => b.year - a.year)
        )
      } else {
        setWorksToDisplay(sortedWorks(works))
      }
      setFilterTHead(filterTHead.map((thead) => {
        if (thead.name == sortBy) {
          return {...thead, isActive: true}
        } else return {...thead, isActive: false}
      }))
    }
  }, [globalData.isAllDataLoaded, typeID, sortBy])

  const handleTagURL = (id) => {
    if (id == typeID) {
      // history.push(`/index&type=*&sortBy=${sortBy}`);
    } else {
      history.push(`/index&type=${id}&sortBy=${sortBy}`);
    }
  }

  // const handleSortURL = (str) => {
  //   history.push(`/index&type=${typeID != '*' ? typeID : '*'}&sortBy=${str}`);
  // };

  const sortedWorks = (works) => {
    return works
      .sort((a, b) => a.number - b.number)
      .sort((a, b) => b.year - a.year)
      .sort((a, b) => a.tag?.order - b.tag?.order)
  }

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
      <Helmet>
        <title>{globalData.artistName} - Projects</title>
      </Helmet>
      <div className="container__index">
        <SectionTitle title='Projects' className='big__font t--UPPS t--CENTER' isMain={true} />
        <FilterTags tags={filterTags}  handleTagURL={handleTagURL} typeID={typeID} />
        {/* {globalData.isAllDataLoaded && <FilterTHead  data={filterTHead} handleSortURL={handleSortURL} />} */}
        {/* <WorksToDisplay works={worksToDisplay}/> */}
        {worksToDisplay &&
          <SectionRelatedWorks
            data={worksToDisplay}
            grid={filterTags.find((tag) => tag.order == typeID)?.grid}
          />
        }
      </div>
    </motion.div>
  );
};

export default Works;