import React, { useEffect, useContext, useState } from 'react';
import SectionTitle from '../../components/SectionTitle';
import SectionLabelContent from '../../components/SectionLabelContent';
import DataContext from '../../config/dataContext';
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from 'react-helmet';
import './updates.scss';

const Updates = ({ data }) => {
  const globalData = useContext(DataContext);
  // const scrollElement = document.getElementById('SCROLL--PRESS');

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor(globalData.informations.page.find((p) => p.page === 'more exhibitions').color.name)
    }
  }, [globalData.isAllDataLoaded])

  // let p = scrollInside.scrollTop + v;
  // scrollInside.scroll({
  //   top: p,
  //   behavior: 'smooth'
  // });

  // TO BE CONTINUED
  // useEffect(() => {
  //   scrollPosition != 0 && scrollElement.scroll({ top: scrollPosition })
  //   console.log(scrollPosition)
  // }, [])

  // useEffect(() => {
  //   let p = scrollInside.scrollTop + v;
  //   scrollElement && scrollElement.scroll({
  //     top: 500,
  //     behavior: 'smooth'
  //   });
  // }, [])

  // const handleScrollPosition = () => {
  //   // setScrollPosition(scrollElement.scrollTop)
  //   // console.log(scrollElement.scrollTop);
  // }

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
      // id='SCROLL--PRESS'
      // onScroll={handleScrollPosition}
    >
      <Helmet>
        <title>{`${globalData.artistName} - More Exhibitions`}</title>
      </Helmet>
      <div className="container__page">
        <SectionTitle title='More Exhibitions' className='big__font t--UPPS t--CENTER' isMain={true} />
        <SectionLabelContent data={data} slug="more-exhibitions" />
      </div>
    </motion.div>
  );
};

export default Updates;
