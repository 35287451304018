import React from "react";
import ReactMarkdown from 'react-markdown'
import './customMarkdown.scss';

const CustomMarkdown = ({ className, content }) => {

  return (
    <ReactMarkdown
      className={`md--RULES ${className}`}
      linkTarget="_blank"
      children="string"
    >
      {content}
    </ReactMarkdown>
  );
};

export default CustomMarkdown;
