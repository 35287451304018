import React, { useEffect, useContext, useState } from 'react';
import SectionTitle from '../../components/SectionTitle';
// import SectionLabelContent from '../../components/SectionLabelContent';
// import SectionContent from '../../components/SectionContent';
import DataContext from '../../config/dataContext';
import CustomMarkdown from '../../components/CustomMarkdown';
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from 'react-helmet';
import './imprint.scss';
import CustomFont from '../../components/CustomFont';
// import SectionCTA from '../../components/SectionCTA';
import CTA from '../../components/CTA';

const Imprint = ({ data }) => {
  const globalData = useContext(DataContext);
  // const scrollElement = document.getElementById('SCROLL--PRESS');

  useEffect(() => {
    if (globalData.isAllDataLoaded === true) {
      globalData.handleCenterLogo(true)
      globalData.handleCurrentColor(globalData.informations.page.find((p) => p.page === 'imprint').color.name)
    }
  }, [globalData.isAllDataLoaded])

  // let p = scrollInside.scrollTop + v;
  // scrollInside.scroll({
  //   top: p,
  //   behavior: 'smooth'
  // });

  // TO BE CONTINUED
  // useEffect(() => {
  //   scrollPosition != 0 && scrollElement.scroll({ top: scrollPosition })
  //   console.log(scrollPosition)
  // }, [])

  // useEffect(() => {
  //   let p = scrollInside.scrollTop + v;
  //   scrollElement && scrollElement.scroll({
  //     top: 500,
  //     behavior: 'smooth'
  //   });
  // }, [])

  // const handleScrollPosition = () => {
  //   // setScrollPosition(scrollElement.scrollTop)
  //   // console.log(scrollElement.scrollTop);
  // }

  return (
    <motion.div
      className="wrapper__page"
      // style={{ height: globalData.maxHeight }}
      // initial={{ x: '100%' }}
      // animate={{ x: 0 }}
      // exit={{ x: '-100%' }}
      // transition={{ duration: 0.8, ease: 'easeInOut' }}
      // id='SCROLL--PRESS'
      // onScroll={handleScrollPosition}
    >
      <Helmet>
        <title>{`${globalData.artistName} - Imprint`}</title>
      </Helmet>
      <div className="container__page">
        <SectionTitle title='Imprint' className='big__font t--UPPS t--CENTER' isMain={true} />
        {/* {globalData.imprint.legals && globalData.imprint.legals.map((legal) =>
          <SectionContent data={legal.description} />
        )} */}
        <section className="section__imprint--content t--MINI--content">
          <ul className="imprint__content">
            {globalData.imprint.legals && globalData.imprint.legals.map((legal) =>
              <li
                key={legal.id}
                className="imprint--item"
              >
                {/* <CustomFont
                  tag="p"
                  className="medium__font t--UPPS TITLE"
                  content={legal.label}
                /> */}
                <CustomMarkdown
                  className="medium__font"
                  content={legal.description}
                />
              </li>
            )}
          </ul>
        </section>
        <section className="section__links t--MINI--content">
          <ul className="links__content">
            {globalData.imprint.links && globalData.imprint.links.map((link) =>
              <li
                key={link.id}
                className="link--item"
              >
                <CustomFont
                  tag="p"
                  className="label__font t--UPPS TITLE"
                  content={link.title}
                />
                <CTA
                  className="label__font t--UPPS"
                  label={link.label}
                  link={link.link}
                />
              </li>
            )}
          </ul>
        </section>
        <section className="section__copyright t--MINI--content">
          <CustomFont
            tag="p"
            className="small__font t--UPPS TITLE"
            content={`all rights reserved © Daniel Gordon Studio ${globalData.currentYear}`}
          />
        </section>
      </div>
    </motion.div>
  );
};

export default Imprint;
