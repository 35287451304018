import React from "react";
import './image.scss';

const Image = ({ src, alt }) => {

  return (
    <img
      className="IMG"
      src={src}
      alt={alt}
      loading="lazy"
    />
  );
};

export default Image;
